<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <b-row>
      <b-col cols="8">
        <div style="margin-top: 26px">
          <p>This is used for language translation only.</p>
          <p>
            <strong>Note:</strong> Changing the context of the field or leave a translation empty <strong>does not</strong> change the logic of the questionaire.
          </p>
        </div>
      </b-col>
    </b-row>
    <div style="height:52px;"></div>

    <b-row>
      <b-col>
        <b-table-simple
          v-if="profiles.length > 0"
          id="table-1"
          hover
          small
          caption-top
          responsive
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th
                v-for="data in header"
                :key="data.title"
                :class="isEditContent"
                :style="data.style"
              >
                {{ data.title }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(profile, index) in profiles"
              :key="profile.profileOriginalId"
            >
              <b-td v-if="index >= 0" style="color: #006db6">
                {{ profile.profileOriginal.original }}
              </b-td>
              <b-td v-if="profile.translation.length === 2">
                <div v-if="isEditable">
                  <CustomEditor 
                    v-model="profile.translation[0]"
                    type="textarea"
                    :set="profile.translation[0] = profile.translation[0].replaceAll('<br>', '\n')"
                    main-class-name="w-100 mb-2"
                    :rows="1"
                    :maxlength="12"
                  />
                  <CustomEditor 
                    v-model="profile.translation[1]"
                    type="textarea"
                    :set="profile.translation[1] = profile.translation[1].replaceAll('<br>', '\n')"
                    main-class-name="w-100 mb-2"
                    :rows="1"
                    :maxlength="26"
                  />
                </div>
                <CustomEditor
                  v-else
                  v-model="data" 
                  :set="data=profile.translation[0].replaceAll('<br>', '\n') + ' ' + profile.translation[1].replaceAll('<br>', '\n')" 
                />
              </b-td>
              <b-td v-else>
                <div v-if="isEditable">
                  <CustomEditor 
                    v-model="profile.translation[0]"
                    type="textarea" 
                    :set="profile.translation[0] = profile.translation[0].replaceAll('<br>', '\n')"
                    main-class-name="w-100 mb-2"
                    :rows="1"
                    :maxlength="
                      index === 0 ? 54 : 
                      [2, 4, 6, 8, 10, 12].indexOf(index) >= 0 ? 260 :
                      [13, 14, 21, 22, 29, 30, 31].indexOf(index) >= 0 ? 60 :
                      [15, 16, 17, 18, 23, 24, 25, 26, 32, 33, 34, 35, 36, 37].indexOf(index) >= 0 ? 90 :
                      [19, 20, 27, 28, 38, 39, 40].indexOf(index) >= 0 ? 210 : 20
                    "
                  />
                </div>
                <CustomEditor
                  v-else
                  v-model="data"
                  :set="data=profile.translation[0].replaceAll('<br>', '\n')"
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
fixHeaderTable
} from "@/common/CommonUtils";
import CustomEditor from "../../components/custom/CustomEditor.vue";
import ProfileService from "../../service/ProfileService";

const HEADERS_TABLE = [
  { title: "SECTION", style: "width: 30%", class: "" },
  { title: "TRANSLATION", style: "width: 70%", class: "" },
];
export default {
  components: {CustomEditor},
  data() {
    const surveyId = this.$store.state.survey.id;
    return {
      header: HEADERS_TABLE,
      surveyId: surveyId <= 0 ? 1 : surveyId,
      isEditable: (this.$store.state.survey.isSurveyEditing = false) && false,
      isShowError: false,
      message: "",
      profiles: []
    };
  },
  computed: {
    isEditContent() {
      return this.isEditable ? "edit-th-content" : "normal-th-content";
    },
  },
  mounted() {
    this.fetch();
  },
  updated() {
    fixHeaderTable("table-1", "header-nav", this.isEditable);
  },
  methods: {
    turnOnEditable() {
      this.isEditable = true;
    },
    turnOffEditable() {
      this.isEditable = false
      this.fetch()
    },
    fetch() {
      let loader = this.$loading.show();
      ProfileService.listProfiles(this.surveyId)
        .then((response) => {
          this.profiles = response.data;
          loader.hide();
        })
        .catch((e) => {
          this.message = e;
          this.isShowError = true;
          loader.hide();
        });
    },
    save(chainFunc) {
      this.isEditable = false;
      ProfileService.saveProfile(this.profiles)
          .then(() => {
            this.fetch();
            if (chainFunc !== undefined)
              chainFunc()
          })
          .catch((e) => {
            console.log(e);
          });
    },
  }
};
</script>

<style scoped>
.content {
  padding: 24px;
}

td {
  word-break: break-word;
}

.form-translation {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.main {
  position: relative;
  display: inline-block;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  background-color: #fff;
  width: 100%;
}

.main textarea {
  border: none!important;
  margin-bottom: 20px;
}

.main textarea:focus, .main textarea:active {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.main span {
  color: #768192;
  position: absolute;
  bottom: .375rem;
  right: .75rem;
  font-size: 10px;
}
</style>